import { SecondNativeArticle } from '@/components/SecondNativeArticle';
import { useAppState } from '@/hooks/useAppState';
import useLazyCallback from '@/hooks/useLazyCallback';
import { useSeenArticleCampaignsLocalStorage } from '@/hooks/useSeenArticleCampaignsLocalStorage';
import { LabradorComponent } from '@/types/component';
import { Content } from '@/types/content';
import { SecondNativeArticleCampaign } from '@/types/secondNativeArticleCampaign';
import { fetchNativeArticle } from 'base/components/SecondNativeArticle/helpers';
import { getSecondNativeArticleCampaigns } from 'lib/data/secondNativeCampaigns';
import getConfig from 'modules/config';
import { useCallback, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { isNull, isUndefined } from 'typesafe-utils';

export const LabradorSecondNativeArticle: LabradorComponent = () => {
  const [{ device, category, pageId, isNativeAd, accessLevel }, appStateDispatcher] = useAppState();
  const [seenCampaignsLocalStorage] = useSeenArticleCampaignsLocalStorage();
  const [pageData, setPageData] = useState<Content | null | undefined>(undefined);
  const [campaign, setCampaign] = useState<SecondNativeArticleCampaign | undefined>(undefined);

  const enabled = !isNativeAd && getConfig('secondNativeArticle.enabled');
  const isPaidContent = accessLevel === 'purchase';

  const fetchActiveCampaigns = async () => {
    if (enabled && !isPaidContent) {
      const campaigns = await getSecondNativeArticleCampaigns(category as string);

      const notSeenCampaign = campaigns.find(
        (c) =>
          c.articleId !== String(pageId) &&
          !seenCampaignsLocalStorage.some(
            ({ campaignId, userFrequency }) =>
              campaignId === c.campaignId && (userFrequency ?? 1) >= (c.userFrequency ?? 1),
          ),
      );

      setCampaign(notSeenCampaign);
    }
  };

  useEffect(() => {
    appStateDispatcher({
      isSecondNativeArticleLoaded: Boolean(campaign),
    });
  }, [campaign]);

  useEffectOnce(() => {
    fetchActiveCampaigns();
  });

  const getNativeArticle = useCallback(async () => {
    const data = await fetchNativeArticle(campaign?.articleId || '', device);

    // make sure the second native article is the same brand as the current article
    const isNativeArticleFromDifferentBrand = data?.type === '308';
    if (!isNativeArticleFromDifferentBrand) {
      setPageData(data);
    }
  }, [campaign?.articleId, device]);

  const ref = useLazyCallback<HTMLDivElement>(getNativeArticle, {
    rootMargin: '3000px',
    root: null,
  });

  if (isPaidContent || !enabled || isNull(pageData) || !campaign) {
    return null;
  }

  if (isUndefined(pageData)) {
    return <div ref={ref} />;
  }

  return (
    <SecondNativeArticle
      data={pageData.data}
      descendants={pageData.children}
      meta={pageData.meta}
      campaign={campaign}
    />
  );
};
