import { IconTheme } from '@/components/Icon/theme';
import { tw } from '@/utils/tw';

const Icon = tw.theme({
  extend: IconTheme,
  base: `absolute right-2.5 top-1/2 z-1 h-4 w-4 -translate-y-1/2 rotate-180`,
});

const BreakingNews = tw.theme({
  slots: {
    base: ``,
    item: `bg-gray-100`,
    blip: ``,
    headline: `text-black`,
    label: ``,
  },
  variants: {
    variant: {
      primary: {
        base: ``,
        item: `px-9 py-2.5`,
        blip: `absolute left-4.5 top-5 inline-block h-2 w-2 rounded-full bg-primary-700 before:absolute before:inline-block before:h-2 before:w-2 before:animate-blip-inner-circle before:rounded-full before:bg-primary-700 after:absolute after:inline-block after:h-2 after:w-2 after:animate-blip-outer-circle after:rounded-full after:bg-primary-700`,
        headline: `inline text-headline-sm sm:text-headline-md`,
        label: `mr-1 text-primary-700 text-headline-sm after:content-[":"] sm:text-headline-md`,
      },

      secondary: {
        base: `[&_svg]:hidden`,
        item: `p-3 sm:p-6`,
        blip: `hidden`,
        headline: `mt-1 block text-headline-md sm:mt-3 sm:text-headline-lg`,
        label: `inline-block bg-primary-700 px-1.5 py-1 uppercase text-white text-headline-xs sm:px-2.5 sm:py-1.5`,
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export default Object.assign(BreakingNews, { Icon });
