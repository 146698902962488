import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseTagsIndexTheme from 'base/components/TagsIndex/theme';

const Tag = tw.theme({
  extend: TagTheme,
  base: ``,
  variants: {
    variant: {
      text: ``,
    },
    size: {
      text: `md:text-body-lg text-body-md`,
    },
    colors: {
      text: ``,
    },
  },
  defaultVariants: {
    variant: 'text',
    size: 'text',
    colors: 'text',
  },
});

const TagsIndex = tw.theme({
  extend: BaseTagsIndexTheme,
  slots: {
    base: `mb-6`,
    headline: `mb-4.5 text-headline-sm md:mb-6 md:text-headline-md`,
    group: ``,
    letter: `text-logga-500 !font-medium text-headline-sm md:text-headline-sm`,
    tags: `gap-y-2 md:gap-y-3`,
  },
});

export default Object.assign(TagsIndex, { Tag });
