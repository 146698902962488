import { useAppState } from '@/hooks/useAppState';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import getCurrentBrand from '@/utils/getCurrentBrand';
import { gridSystemBreakpoints } from 'base/configs/gridSystem';
import { useId, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { VideoReels } from './VideoReels';

export interface StandaloneVideoReelsProps extends StandaloneComponentProps<typeof VideoReels> {
  scriptSrc?: string;
  playlistId?: string;
}

export const StandaloneVideoReels: StandaloneComponent<StandaloneVideoReelsProps> = ({
  scriptSrc,
  playlistId,
  options,
  ...props
}) => {
  const { $headline, ...baseProps } = options ?? {};

  const [{ isNativeAd }] = useAppState();
  const [isPlayerInserted, setIsPlayerInserted] = useState(false);
  const [isListInserted, setIsListInserted] = useState(false);
  const videoRef = useRef<HTMLDivElement>(null);
  const id = useId();

  const isInvalid = isNativeAd || !playlistId || !scriptSrc;

  useEffectOnce(() => {
    const isVideoReelsRegistered = Boolean(window.customElements.get('bb-thumbnail'));
    const scriptInjected = Array.from(videoRef.current?.childNodes ?? []).some((node) => node.nodeName === 'SCRIPT');

    if (isVideoReelsRegistered || isInvalid || scriptInjected) {
      return;
    }

    // it has to be injected that way because player is injected next to the script and next.js hoist script tags higher in DOM - fix in progress by Blue billywig
    const script = document.createElement('script');
    script.src = scriptSrc;
    videoRef.current?.appendChild(script);

    // purpose of the observer
    // 1. set different thumbnail height for mobile
    // 2. set z-index for player to cover fullscreen mode
    // 3. show widget only if clips are loaded

    const observer = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          const webComponent = Array.from(mutation.addedNodes).find((node) => node.nodeName === 'BB-SHORTS') as
            | HTMLElement
            | undefined;

          if (webComponent) {
            const webComponentObserver = new MutationObserver((webComponentMutationList) => {
              for (const webComponentMutation of webComponentMutationList) {
                const webComponentNodeAdded = webComponentMutation.addedNodes[0] as HTMLElement;

                const wrapperInserted = webComponentNodeAdded?.classList?.contains('wrapper');
                const playerInserted = webComponentNodeAdded?.classList?.contains('mode-experience');
                const listInserted = webComponentNodeAdded?.classList?.contains('strip-container');
                const isMobile = window.innerWidth <= gridSystemBreakpoints.sm;

                if (wrapperInserted && isMobile) {
                  webComponentNodeAdded?.style?.setProperty('--thumbnail-height', '230px');
                }

                if (playerInserted) {
                  webComponentNodeAdded.style.zIndex = '100';
                  setIsPlayerInserted(true);
                }

                if (listInserted) {
                  videoRef.current?.classList.remove('hidden');
                  setIsListInserted(true);
                }

                if (isPlayerInserted && isListInserted) {
                  webComponentObserver.disconnect();
                }
              }
            });
            observer.disconnect();

            const bbShortsShadowRoot = webComponent?.shadowRoot as Node;

            bbShortsShadowRoot.addEventListener('click', () => window.jwplayer().pause());

            webComponentObserver.observe(bbShortsShadowRoot, {
              childList: true,
              subtree: true,
            });
          }
        }
      }
    });

    observer.observe(videoRef.current as Node, { childList: true, subtree: true });
  });

  if (isInvalid) {
    return null;
  }

  return (
    <div className="cts-impression-group">
      <VideoReels
        data-cts-label="video_reels"
        data-cts-name="video_reels_main"
        data-cts-creative="video_reels"
        data-cts-id={playlistId}
        id={id}
        ref={videoRef}
        {...baseProps}
        {...props}
      >
        <VideoReels.Headline {...$headline}>{getCurrentBrand().name} reels</VideoReels.Headline>
      </VideoReels>
    </div>
  );
};
