import { RecommendedArticles } from '@/components/RecommendedArticles';
import { useAppState } from '@/hooks/useAppState';
import useLazyCallback from '@/hooks/useLazyCallback';
import { LabradorComponent } from '@/types/component';
import logger from '@/utils/logger';
import { RecommendedArticlesData } from 'base/components/RecommendedArticles';
import { createArticleDataStructure } from 'base/components/RecommendedArticles/helpers';
import { getRecommendedArticles, readyToFetch } from 'base/components/RecommendedArticles/services';
import getConfig from 'modules/config';
import { useCallback, useState } from 'react';
import { isNotTrue, isNull } from 'typesafe-utils';

export const LabradorRecommendedArticles: LabradorComponent = () => {
  const [{ pageId }] = useAppState();

  const [articles, setArticles] = useState<RecommendedArticlesData | null>([]);
  const fetchArticles = useCallback(async () => {
    const isReady = await readyToFetch();

    if (!isReady) {
      return setArticles(null);
    }

    const articlesData = await getRecommendedArticles(String(pageId)).catch(logger.catch);

    if (!articlesData) {
      return setArticles(null);
    }

    const transformedData = createArticleDataStructure(articlesData);
    setArticles(transformedData);
  }, [pageId]);

  const ref = useLazyCallback<HTMLDivElement>(fetchArticles, {
    rootMargin: '1000px',
    root: null,
  });

  if (isNotTrue(getConfig('recommendedArticles.enabled')) || isNull(articles)) return null;

  return articles?.length > 0 ? <RecommendedArticles articles={articles} /> : <div ref={ref} />;
};
