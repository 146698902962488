/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { AllasStandaloneArticleTeaserBodytext as ResolvedStandaloneArticleTeaserBodytext } from 'sites/allas/components/standalone/ArticleTeaser/Bodytext';

export const ArticleTeaserBodytext: typeof ResolvedStandaloneArticleTeaserBodytext = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneArticleTeaserBodytext {...props} />
        </ErrorBoundary>
    );
});

export type ArticleTeaserBodytextProps = PropsFromComponent<typeof ArticleTeaserBodytext>;

/** @deprecated Use slot architecture instead */
export const ArticleTeaserBodytextWith = (extras: DynamicStandaloneExtras): typeof ArticleTeaserBodytext => {
    return function ArticleTeaserBodytext(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ArticleTeaserBodytext');
        return <ResolvedStandaloneArticleTeaserBodytext {...mergeProps({ options: { theme } }, props)} />;
    }
}