/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneBreakingNews as ResolvedStandaloneBreakingNews } from 'base/components/standalone/BreakingNews';

export const BreakingNews: typeof ResolvedStandaloneBreakingNews = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneBreakingNews {...props} />
        </ErrorBoundary>
    );
});

export type BreakingNewsProps = PropsFromComponent<typeof BreakingNews>;

/** @deprecated Use slot architecture instead */
export const BreakingNewsWith = (extras: DynamicStandaloneExtras): typeof BreakingNews => {
    return function BreakingNews(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'BreakingNews');
        return <ResolvedStandaloneBreakingNews {...mergeProps({ options: { theme } }, props)} />;
    }
}