import { AffiliateItemTheme } from '@/components/AffiliateItem/theme';
import { Image as StandaloneImage } from '@/components/Image';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: AffiliateItemTheme });

const Base = $({ as: 'div', slot: 'base' });
const Headline = $({ as: 'h2', slot: 'headline' });
const Description = $({ as: 'p', slot: 'description' });
const Counter = $({ as: 'span', slot: 'counter' });
const Subtitle = $({ as: 'span', slot: 'subtitle' });
const Group = $({ as: 'div', slot: 'group' });
const Product = $({ as: 'div', slot: 'product' });
const ProductHeadline = $({ as: 'h5', slot: 'productHeadline' });
const ProductCaption = $({ as: 'span', slot: 'productCaption' });
const ProductVendor = $({ as: 'span', slot: 'productVendor' });
const ProductImage = $({
  as: StandaloneImage,
  //TODO: pass theme when Image is refactored
});

export const AffiliateItem = Object.assign(Base, {
  Headline,
  Description,
  Counter,
  Subtitle,
  Group,
  Product,
  ProductHeadline,
  ProductCaption,
  ProductVendor,
  ProductImage,
});
