/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { AllasLabradorNewsletterSignup as ResolvedLabradorNewsletterSignup } from 'sites/allas/components/labrador/NewsletterSignup';

export const LabradorNewsletterSignup: typeof ResolvedLabradorNewsletterSignup = withLabradorInstanceof((props) => {
    return <ResolvedLabradorNewsletterSignup {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorNewsletterSignupProps = PropsFromComponent<typeof LabradorNewsletterSignup>;
