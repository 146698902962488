import { BreakingNewsTheme } from '@/components/BreakingNews/theme';
import { Icon as StandaloneIcon } from '@/components/Icon';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: BreakingNewsTheme });

const Base = $({ as: 'section', slot: 'base' });
const Item = $({ as: 'div', slot: 'item' });
const Blip = $({ as: 'div', slot: 'blip' });
const Headline = $({ as: 'h3', slot: 'headline' });
const Label = $({ as: 'span', slot: 'label' });
const Icon = $({ as: StandaloneIcon, theme: BreakingNewsTheme.Icon });

export const BreakingNews = Object.assign(Base, {
  Item,
  Blip,
  Headline,
  Label,
  Icon,
});
