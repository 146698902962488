import { BreakingNews } from '@/components/BreakingNews';
import { LabradorComponent } from '@/types/component';
import { StandaloneBreakingNewsProps } from 'base/components/BreakingNews';
import { getBreakingNewsArticles } from 'lib/data/breakingNews';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

export const LabradorBreakingNews: LabradorComponent = ({ type, meta, data, descendants, ...props }) => {
  const [articles, setArticles] = useState<StandaloneBreakingNewsProps['articles']>([]);

  useEffectOnce(() => {
    getBreakingNewsArticles({
      articleAge: data.breakingNewsAge,
      title: data.breakingNewsTitle,
    }).then((items) => setArticles(items));
  });

  return <BreakingNews articles={articles} {...props} />;
};
