import { Data } from '@/types/content';
import { B2BVideoInfo, VideoPlayerType } from '../types/video';

export const getVideoOptions = ({
  videoID,
  thumbnail,
  mute,
  title,
  duration,
  date,
  publishedAt,
  autostart,
  floating,
  articleInfo,
  aspectratio,
  description,
  ypPartId,
  playlist,
  isNativeAd,
  prominentTag,
  isProtected,
}: Data): VideoPlayerType => ({
  videoId: videoID,
  thumbnail,
  mute,
  title,
  duration,
  date,
  publishedAt,
  autostart,
  floating,
  articleInfo,
  ...(aspectratio ? { aspectRatio: aspectratio } : {}),
  description,
  ypPartId,
  playlist,
  isNativeAd,
  prominentTag,
  hideAds: isProtected,
});

export const getB2BVideoInfo = ({ link, linkText, markingText }: Data): B2BVideoInfo => ({
  link,
  linkText,
  markingText,
});
