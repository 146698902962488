import { Icon, IconProps } from '@/components/Icon';
import { useClassName } from '@/styles/ShowsSlider';
import { Component, ComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import mergeProps from 'lib/utils/mergeProps';

export interface ShowsSliderCaptionProps extends ComponentProps<'div'> {
  $icon?: IconProps['options'];
}

export const ShowsSliderCaptionComponent: Component<ShowsSliderCaptionProps> = ({ children, $icon, ...props }) => {
  const className = useClassName('caption', props);
  const iconClassName = useClassName('icon', props);

  return (
    <div {...mergeProps({ className }, props)}>
      {children}
      <Icon
        name="teaserArrow"
        options={mergeOptions(
          {
            className: iconClassName,
          },
          $icon,
        )}
      />
    </div>
  );
};
