import { tw } from '@/utils/tw';

const Byline = tw.theme({
  slots: {
    base: `flex items-center`,
    author: `text-headline-xs`,
    text: `mr-1 text-gray-700 text-headline-xs`,
  },
});

export default Byline;
