import { Link, LinkProps } from '@/components/Link';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { A11y, Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide, SwiperSlideProps } from 'swiper/react';
import { BreakingNews } from './BreakingNews';

import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

interface BreakingNewsArticle {
  label?: React.ReactNode;
  headline?: React.ReactNode;
  link?: LinkProps;
}

export interface StandaloneBreakingNewsProps
  extends StandaloneComponentProps<
    typeof BreakingNews,
    {
      $swiper?: SwiperProps;
      $slide?: SwiperSlideProps;
    }
  > {
  articles?: BreakingNewsArticle[];
}

export const StandaloneBreakingNews: StandaloneComponent<StandaloneBreakingNewsProps> = ({ articles, ...props }) => {
  if (!articles?.length) {
    return null;
  }

  return (
    <BreakingNews {...props}>
      <Swiper
        className="w-full"
        modules={[Navigation, Autoplay, A11y]}
        grabCursor
        autoplay
        loop
        speed={2500}
        {...props.options?.$swiper}
      >
        {articles.map(({ label, headline, link }, index) => (
          <SwiperSlide key={index} {...props.options?.$slide}>
            <Link
              data-cts-creative={`article-teaser-breaking:${headline}`}
              data-cts-path={link?.href}
              data-cts-label={`article-teaser-breaking:${headline}`}
              data-cts-name={link?.href}
              data-cts-id={link?.href?.split('/').at(-1)}
              content={
                <BreakingNews.Item>
                  <BreakingNews.Blip />
                  <BreakingNews.Label>{label}</BreakingNews.Label>
                  <BreakingNews.Headline>{headline}</BreakingNews.Headline>
                  <BreakingNews.Icon name="sliderArrow" />
                </BreakingNews.Item>
              }
              {...link}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </BreakingNews>
  );
};
