import { tw } from '@/utils/tw';
import BaseBreakingNewsTheme from 'base/components/BreakingNews/theme';

const Icon = tw.theme({
  extend: BaseBreakingNewsTheme.Icon,
});

const BreakingNews = tw.theme({
  extend: BaseBreakingNewsTheme,
  variants: {
    variant: {
      primary: {
        item: `bg-yellow-600`,
        blip: `bg-logga-600 before:bg-logga-600 after:bg-logga-600`,
        headline: `text-body-sm sm:text-body-md`,
        label: `text-logga-600 !font-medium text-body-sm sm:text-body-md`,
      },
    },
  },
});

export default Object.assign(BreakingNews, { Icon });
