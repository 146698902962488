/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { AllasStandaloneAffiliateGallery as ResolvedStandaloneAffiliateGallery } from 'sites/allas/components/standalone/AffiliateGallery';

export const AffiliateGallery: typeof ResolvedStandaloneAffiliateGallery = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneAffiliateGallery {...props} />
        </ErrorBoundary>
    );
});

export type AffiliateGalleryProps = PropsFromComponent<typeof AffiliateGallery>;

/** @deprecated Use slot architecture instead */
export const AffiliateGalleryWith = (extras: DynamicStandaloneExtras): typeof AffiliateGallery => {
    return function AffiliateGallery(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'AffiliateGallery');
        return <ResolvedStandaloneAffiliateGallery {...mergeProps({ options: { theme } }, props)} />;
    }
}