import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { KeesingGames } from 'base/components/KeesingGames/KeesingGames';
import Script from 'next/script';
import getConfig from '../../../../modules/config';
import { useSearchParam } from 'react-use';

export interface StandaloneKeesingGamesProps extends StandaloneComponentProps<typeof KeesingGames> {}

export const StandaloneKeesingGames: StandaloneComponent<StandaloneKeesingGamesProps> = ({ ...props }) => {
  const { customerId, scriptPath, frontPagePathname } = getConfig('keesingGames');
  const isPlaying = Boolean(useSearchParam('gametype'));

  if (!customerId || !scriptPath) return null;

  return (
    <KeesingGames {...props}>
      <KeesingGames.PuzzlePortal data-playerpath={frontPagePathname} data-customerid={customerId} id="puzzle-portal" />
      <Script type="text/javascript" src={scriptPath} data-wlpp-bundle={isPlaying ? 'player' : 'portal'} />
    </KeesingGames>
  );
};
