import getUrl from '@/utils/getUrl';
import { http } from '@/utils/http';
import { StandaloneBreakingNewsProps } from 'base/components/BreakingNews';
import getConfig from 'modules/config';

const filterByAge = (breakingNewsAge: number) => (article: any) => {
  if (!breakingNewsAge) return true;

  const breakingNewsAgeInMS = breakingNewsAge * 60 * 1000;

  const publishDateTime = new Date(article.published).getTime();
  const nowTime = new Date().getTime();

  return nowTime - publishDateTime <= breakingNewsAgeInMS;
};

interface Config {
  articleAge: number;
  title: string;
}

export const getBreakingNewsArticles = async (config: Config): Promise<StandaloneBreakingNewsProps['articles']> => {
  const numberOfArticles = getConfig('breakingNews.numberOfArticles') || 3;

  const siteId = process.env.OCELOT_LABRADOR_SITE_ID;
  const api = process.env.OCELOT_LABRADOR_API;
  const request = getUrl('/article', api);

  if (!request || !config.articleAge) return [];

  request.searchParams.set('query', `visibility_status:P AND breaking:(1) AND lab_site_id:${siteId}`);
  request.searchParams.set('order', 'desc');
  request.searchParams.set('orderBy', 'published');
  request.searchParams.set('limit', numberOfArticles);

  const response = await http.get(request.href);

  const articles: StandaloneBreakingNewsProps['articles'] = (response?.data?.result ?? [])
    .filter(filterByAge(config.articleAge))
    .map((article: any) => ({
      headline: article.breakingHeadline || article.title,
      label: config.title,
      link: {
        href: article.published_url,
      },
    }));

  return articles;
};
