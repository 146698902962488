import { tw } from '@/utils/tw';

const KeesingGames = tw.theme({
  slots: {
    base: [],
    puzzlePortal: [
      '[&_div.mobile-device.mobile_.puzzle-player]:!w-screen',
      '[&_div.mobile-device.mobile_.puzzle-player]:!max-w-[100vw]',
      '[&_div.mobile-device.mobile_.puzzle-player]:!ml-[calc(-50vw+50%)]',
    ],
  },
});

export default KeesingGames;
