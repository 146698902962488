import { tw } from '@/utils/tw';
import BaseBylineTheme from 'base/components/Byline/theme';

const Byline = tw.theme({
  extend: BaseBylineTheme,
  slots: {
    author: `text-body-md`,
    text: `text-black text-body-md`,
  },
});

export default Byline;
