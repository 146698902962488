/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "content_size_default": "gap-6",
  "content_colors_default": "",
  "content_variant_default": "flex flex-col w-full",
  "headline_size_default": "text-headline-lg md:text-headline-2xl",
  "headline_colors_default": "",
  "headline_variant_default": "",
  "category_size_default": "",
  "category_colors_default": "",
  "category_variant_default": "capitalize",
  "button_size_default": "",
  "button_colors_default": "",
  "button_variant_default": "",
  "description_size_default": "text-body-sm",
  "image_size_default": "min-w-[180px] md:min-w-[300px] aspect-vertical",
  "image_colors_default": "",
  "image_variant_default": "relative",
  "size_default": "gap-4 md:gap-48 px-4 py-3 md:p-8",
  "colors_default": "from-white to-gray-100",
  "variant_default": "flex flex-col-reverse md:flex-row items-center bg-gradient-to-b justify-between"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "ShowHero");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;