/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorBreakingNews as ResolvedLabradorBreakingNews } from 'base/components/labrador/BreakingNews';

export const LabradorBreakingNews: typeof ResolvedLabradorBreakingNews = withLabradorInstanceof((props) => {
    return <ResolvedLabradorBreakingNews {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorBreakingNewsProps = PropsFromComponent<typeof LabradorBreakingNews>;
